import { graphql } from '@orthly/graphql-inline-react';

export const PendingVariantFeedback_Fragment = graphql(`
    fragment PendingVariantFeedback_Fragment on VariantFeedbackWithOrderInfoDTO {
        id
        practice_id
        status
        created_at
        lab_order_id
        lab_order_item_id
        order_no
        patient_name
        doctor_name
        order_created_date
        order_delivery_date
        items_v2 {
            ...OrderItemV2DTO
        }
    }
`);

export const GetAllPendingFeedback_Query = graphql(`
    query GetAllPendingVariantFeedback($data: GetAllPendingVariantFeedbackInput!) {
        getAllPendingVariantFeedback(data: $data) {
            ...PendingVariantFeedback_Fragment
        }
    }
`);

export const SubmitVariantFeedback_Mutation = graphql(`
    mutation SubmitVariantFeedback($data: SubmitVariantFeedbackInput!) {
        submitVariantFeedback(data: $data) {
            id
        }
    }
`);
