import Clock from '../../../../assets/icons/Clock.png';
import type { StarRatingType } from '../../../inbox/components/tracker/OrderSummaryStepper';
import { StarRatingInput } from '../../../inbox/components/tracker/OrderSummaryStepper';
import type { SubmitVariantFeedbackArgs } from '../../VariantFeedbackRoot.graphql';
import { WhichCrownDidYouSeatSelector } from '../WhichCrownDidYouSeatSelector';
import { styled, SimpleInput } from '@orthly/ui';
import {
    FlossPalette,
    FormControlLabel,
    Grid,
    InputAdornment,
    RadioPrimitive as Radio,
    RadioGroupPrimitive as RadioGroup,
    Text,
    Button,
} from '@orthly/ui-primitives';
import React from 'react';

export interface OrderItemVariantFeedbackFormProps {
    isMobile: boolean;
    onSubmit: (args: SubmitVariantFeedbackArgs) => void;
}

const Layout = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '10px',
});

const SINGLE_WIDTH = '256px';
const DOUBLE_WIDTH = '512px';

const RadioButton = styled(FormControlLabel)({
    border: `2px solid ${FlossPalette.STROKE_LIGHT}`,
    borderRadius: '16px',
});

const TextInput = styled(SimpleInput)({});

const TextAreaInput = styled(SimpleInput)({});

const SubmitButton = styled(Button)({});

export const OrderItemVariantFeedbackForm: React.FC<OrderItemVariantFeedbackFormProps> = ({ isMobile, onSubmit }) => {
    const [crownChoice, setCrownChoice] = React.useState<number | undefined>(undefined);
    const [wasDropIn, setWasDropIn] = React.useState<boolean | undefined>(undefined);

    // For Time To Seat we want to have freeform input, but only numbers, so the validation is a little longer.
    const [timeToSeat, setTimeToSeat] = React.useState<string | undefined>(undefined);
    let isValidTimeToSeat = false;
    if (timeToSeat) {
        const parsedVal = +timeToSeat;
        isValidTimeToSeat = !Number.isNaN(parsedVal) && parsedVal >= 0;
    }
    const showTimeToSeatError = timeToSeat !== undefined && !isValidTimeToSeat; // Do not show if we don't have a value yet (i.e. is undefined)

    const [starRating, setStarRating] = React.useState<StarRatingType>(0);
    const [freeformFeedback, setFreeformFeedback] = React.useState<string>('');

    // We only want to allow submission if everything required is filled out. Freeform feedback is optional.
    const areWeAllGoodToSubmit =
        crownChoice !== undefined && wasDropIn !== undefined && starRating > 0 && isValidTimeToSeat;

    const submitFn = () => {
        if (!crownChoice || !wasDropIn || !isValidTimeToSeat) {
            // ERROR, I guess
            return;
        }

        onSubmit({
            crownChoice,
            wasDropIn,
            starRating,
            timeToSeat: timeToSeat ? +timeToSeat : -1,
            freeformFeedback,
        });
    };

    return (
        <Layout>
            <Text variant={'h5'}>Which crown did you seat?</Text>
            <WhichCrownDidYouSeatSelector
                onItemSelected={setCrownChoice}
                selectedItem={crownChoice}
                isMobile={isMobile}
            />
            <Text variant={'h5'}>Did we deliver a drop-in crown?</Text>
            <RadioGroup
                key={`RadioGroup-WasDropIn-${wasDropIn}`}
                value={wasDropIn}
                onChange={changed => setWasDropIn(changed.target.value === 'true')}
            >
                <Grid container item direction={'column'} sx={{ gap: '8px' }}>
                    <RadioButton
                        sx={{ maxWidth: isMobile ? '100%' : SINGLE_WIDTH }}
                        value={true}
                        control={<Radio />}
                        label={'Yes'}
                    />
                    <RadioButton
                        sx={{ maxWidth: isMobile ? '100%' : SINGLE_WIDTH }}
                        value={false}
                        control={<Radio />}
                        label={'No'}
                    />
                </Grid>
            </RadioGroup>
            <Text variant={'h5'}>How long did it take to seat the crown?</Text>
            <TextInput
                sx={{ maxWidth: isMobile ? '100%' : SINGLE_WIDTH }}
                onChange={val => setTimeToSeat(val ?? '')}
                value={timeToSeat}
                label={'Minutes'}
                TextFieldProps={{
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position={`end`} style={{ height: 30, paddingBottom: 4 }}>
                                <img src={Clock} style={{ marginRight: 18, height: 18, width: 18 }} alt={``} />
                            </InputAdornment>
                        ),
                        disableUnderline: true,
                        style: { borderRadius: `8px` },
                    },
                    error: showTimeToSeatError,
                    helperText: showTimeToSeatError ? `Please enter a valid number of minutes` : ``,
                    multiline: false,
                }}
            />
            <Text variant={'h5'}>How would you rate this order?</Text>
            <StarRatingInput rating={starRating} onChange={setStarRating} />
            <Text variant={'h5'}>Any additional feedback?</Text>
            <TextAreaInput
                sx={{ maxWidth: isMobile ? '100%' : DOUBLE_WIDTH }}
                TextFieldProps={{ multiline: true, rows: 4 }}
                onChange={val => setFreeformFeedback(val ?? '')}
                value={freeformFeedback}
                label={'Feedback'}
            />
            <SubmitButton
                sx={{ maxWidth: isMobile ? '100%' : SINGLE_WIDTH }}
                variant={'primary'}
                onClick={submitFn}
                disabled={!areWeAllGoodToSubmit}
            >
                Submit
            </SubmitButton>
        </Layout>
    );
};
